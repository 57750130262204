import React from 'react'

import classNames from 'classnames'
import DefaultLayout from '../../layout/default'

import CtaForm from '../../pagesSections/index/CtaForm'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/docs.module.scss'
import AdaptiveLink from '../../components/AdaptiveLink'
import { pagesLinks } from '../../pages-data/common/links'

export default function PagePrivacyPolicy() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
    // },
    {
      name: 'description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/licenseAgreement/annex2',
    },
    {
      property: 'og:title',
      content: 'Лицензионный договор на право использования системы автоматизации Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ]

  return (
    <DefaultLayout
      title="Приложение № 2"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  styles.docs,
      )}
      linkCanonical="https://quickresto.ru/licenseAgreement/annex2/"
      footerClassName={pageStyles.pageSection}
    >
      <section
        className={classNames(styles.docs__container, pageStyles.pageSection)}
      >
        <div className={styles.docs__content}>
          <h1 className={styles.bigMb}>
            Специальные условия Лицензии на Опциональную программу -
            «Брендированный клиентский сайт»
          </h1>

          <div>
            <p>
              Приложение № 2
              к Лицензионному договору на право использования
              программы для ЭВМ «Система автоматизации предприятий
              общественного питания «Quick Resto»» и иных
              программных продуктов ООО «Квик Ресто»
            </p>

          </div>

          <div>
            <p/>

            <p>1. Настоящее Приложение, как и Лицензионный договор, является офертой <br/> ООО&nbsp;<strong>&laquo;Квик
              Ресто&raquo;</strong>&nbsp;(ОГРН 5137746099046, ИНН&nbsp;7726734798, КПП 771401001, 125167 <br/> г.
              Москва, Ленинградский пр. 36, стр. 30, пом. 96), именуемого в дальнейшем Лицензиар, Пользователю,
              именуемому в дальнейшем Лицензиат или Оператор персональных данных.</p>
            <p>2. Под офертой согласно настоящему Приложению № 2 (далее &ndash; &laquo;Приложение № 2&raquo;)
              подразумевается предложение Лицензиара Лицензиату приобрести за дополнительное вознаграждение простую
              (неисключительную) лицензию на результат интеллектуальной деятельности ООО &laquo;Квик
              Ресто&raquo; &ndash; разработанную Лицензиаром программу для ЭВМ &ndash; &laquo;Брендированный клиентский
              сайт&raquo; (далее &ndash; &laquo;Сервис &laquo;Клиентский сайт&raquo;).</p>
            <p>Сервис &laquo;Клиентский сайт&raquo; позволяет Лицензиату на основе макета, имеющегося в
              Сервисе &laquo;Клиентский сайт&raquo;, самостоятельно формировать (наполнять содержимым) и редактировать
              свой сайт в сети интернет, в том числе создавать на своем сайте электронное меню предлагаемых Лицензиатом
              блюд и напитков с указанием их названия, описания состава и стоимости, отображать текущие акции в
              заведении с указанием описания, периода действия (Тарифный
              план &ndash; &laquo;base&raquo; (&laquo;базовый&raquo;)).</p>
            <p>Кроме того, расширенная версия Сервиса &laquo;Клиентский сайт&raquo; (Тарифный
              план &ndash; &laquo;full&raquo; (&laquo;полный&raquo;)) предоставляет следующую дополнительную
              возможность: позволяет клиентам Лицензиата оформлять доставку блюд и напитков в соответствии с их
              заказами, сформированными дистанционным способом на основании электронного меню на сайте Лицензиата.</p>
            <p>Сервис &laquo;Клиентский сайт&raquo; является универсальным программным продуктом и предоставляется
              пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;. Лицензиар не участвует в формировании
              (наполнении содержимым) и редактировании сайта Лицензиара в сети интернет, указанные действия выполняются
              Лицензиатом самостоятельно.</p>
            <p>3. Лицензиар гарантирует Лицензиату, что он является законным правообладателем программы для ЭВМ
              - &laquo;Брендированный клиентский сайт&raquo;</p>
            <p>4. Акцептом Лицензионного договора в части, касающейся Сервиса &laquo;Клиентский сайт&raquo; является
              факт оплаты лицензионного вознаграждения за использование Сервиса &laquo;Клиентский сайт&raquo; согласно
              счету на оплату, выставленного Лицензиаром Лицензиату в соответствии с Тарифным планом, выбранным
              Лицензиатом.</p>
            <p>Срок действия Лицензии на использование Сервиса &laquo;Клиентский сайт&raquo; и срок доступа к нему
              определяются в соответствии с пунктом 4.2. Лицензионного договора на право использования программы для
              ЭВМ &laquo;Система автоматизации предприятий общественного питания &laquo;Quick Resto&raquo;&raquo; и иных
              программных продуктов ООО &laquo;Квик
              Ресто&raquo; (далее &ndash; &laquo;Договор&raquo; или &laquo;Лицензионный договор&raquo;).</p>
            <p>По итогам каждого периода использования прав на Клиентский сайт Лицензиар направляет на электронную
              почту, указанную Лицензиатом, акт исполнения обязательств по настоящему Договору. При отсутствии у
              Лицензиата в течение 3 (трех) календарных дней с момента направления ему данного акта мотивированных
              возражений данный акт считается согласованным и подписанным Лицензиатом.</p>
            <p>5. В дальнейшем продление Лицензии и оплата вознаграждения за предоставление Лицензии на
              Сервис &laquo;Клиентский сайт&raquo; осуществляется на условиях Лицензионного договора.</p>
            <p>6. Сервис &laquo;Клиентский сайт&raquo; размещается в интернет на домене Лицензиара по адресу: в
              формате <u><a
                  href="https://xn--_-7sbabhdoedbneki5dej93a.menuquickresto.ru/">https://название_заведения.menuquickresto.ru/</a></u> ,
              где название заведения предоставляется клиентом, либо привязывается к домену клиента, при оплате
              соответствующей услуги.</p>
            <p><br/>7. Во всем ином, не предусмотренном настоящим Приложением № 2, применяются положения Лицензионного
              договора, а также положения действующего законодательства Российской Федерации.</p>
            <p><br/>8. Краткое описание Сервиса &laquo;Клиентский сайт&raquo; и возможностей данной программы, а также
              преимуществ содержится по следующей интернет ссылке: <u><a
                  href="https://quickresto.ru/emenu/">https://quickresto.ru/emenu/</a></u> и <u><a
                  href="https://quickresto.ru/wl/">https://quickresto.ru/wl/</a></u></p>
            <p>9. Лицензиат подтверждает, что он:</p>
            <p>&middot;&nbsp;перед заключением настоящего Приложения № 2 в полном объеме ознакомлен с функционалом
              (возможностями) Сервиса &laquo;Клиентский сайт&raquo;, в том числе, получения консультаций Лицензиара по
              использованию Сервиса &laquo;Клиентский сайт (по работе с Сервисом &laquo;Клиентский сайт&raquo;);</p>
            <p>&middot; перед заключением настоящего Приложения № 2 в полном объеме ознакомлен с текстом Лицензионного
              договора на право использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
              питания &laquo;Quick Resto&raquo;&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;, в
              котором содержатся все основные условия предоставления лицензий на программные продукты ООО &laquo;Квик
              Ресто&raquo; и который содержится по следующей интернет ссылке: <u><a
                  href="https://quickresto.ru/licenseAgreement/">https://quickresto.ru/licenseAgreement/</a></u>;</p>
            <p>&middot;&nbsp;осознаёт, что Сервис &laquo;Клиентский сайт&raquo; является универсальным программным
              продуктом и предоставляется пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;;</p>
            <p>&middot; осознаёт, что, используя функциональные возможности Сервиса &laquo;Клиентский сайт&raquo;,
              Лицензиат самостоятельно формирует (наполняет содержимым) и редактирует свой сайт в сети интернет;</p>
            <p>- осознает, что при неуплате продления Лицензии на сервис Клиентский сайт, Лицензиар вправе удалить
              Клиентский сайт Лицензиата и отключить функционал настройки в облачном бэк-офисе Лицензиата;</p>
            <p>&middot;&nbsp;заключает настоящее Приложение № 2, так как убедился в том, что Сервис &laquo;Клиентский
              сайт&raquo; в полной мере соответствует потребностям и ожиданиям Лицензиата.<br/> &middot; Несет Расходы
              на авторизацию на основании п. 13.4 Лицензионного соглашения. <br/> &middot; Не несет расходы на хранение
              пользовательских данных Лицензиата, а также техническую поддержку учётных записей, только на период
              действия оплаченной Лицензии.</p>

          </div>
        </div>
      </section>

      <CtaForm
          isFry={false}
          className={pageStyles.pageSection}
          isWhiteBackground
      />
    </DefaultLayout>
  )
}
